@import './GameAnalysis.css';

/* Player Insights Specific Styles */
.player-insights-container {
  max-width: 800px;
  height: 320px;
  margin: 10vh auto; /* Adjusted top margin to push the container down */
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;  /* Align items to the top */
  height: 100vh;
  padding-top: 20vh;  /* Adjust this value to move the card up */
  background-color: #f5f5f5;
}

.player-insights-container h2 {
  color: #333;
}

.player-insights-container h3 {
  margin-top: 20px;
  color: #333;
}

.player-insights-container h4 {
  margin-top: 40px;
  color: #666;
}

.player-insights-container table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 20px;
  border: 1px solid #ccc;
}

.player-insights-container th, .player-insights-container td {
  padding: 10px;
  text-align: center;
  border: none;
}

.player-insights-container th {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
}

.player-insights-container tr:last-child td {
  border-bottom: none;
}

.player-insights-container tr:hover {
  background-color: #f0f0f0;
}

.player-insights-container .positive {
  color: green;
}

.player-insights-container .negative {
  color: red;
}

.table-player{
  border-radius: 10px;
}

.player-insights-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navigation {
  margin-top: -20px;
  display: flex;
  align-items: center;
}

.arrow-btn {
  background: none;
  border: none;
  font-size: 1.5em;
  color: #000; /* Adjust the color based on your design */
}

.arrow-btn:focus {
  outline: none;
}

.name-container {
  width: 20ch; /* ch unit corresponds to the width of the "0" character */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Optional: to handle names longer than 14 characters */
}

