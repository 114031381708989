.specific-hand-insights {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .hand-details {
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .hand-details p {
    margin: 10px 0;
  }
  
  .positive {
    color: green;
  }
  
  .negative {
    color: red;
  }
  
  .action-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  button {
    padding: 10px 20px;
    background-color: #3F9B0A;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }