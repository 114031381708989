.game-analysis-container {
    max-width: 800px;
    height: 400px;
    margin: 10vh auto; /* Adjusted top margin to push the container down */
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #ffffff;
  }
  
  h2 {
    color: #333;
  }
  
  .table-wrapper {
    max-height: 300px;
    overflow-y: auto;
    margin: 20px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  
  th {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  
  tr:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
  }
  
  .game-stats, .player-stats {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .game-stats div, .player-stats div {
    flex: 1;
    text-align: center;
  }
  
  .game-stats p, .player-stats p {
    margin: 0;
    font-size: 14px;
    color: #666;
  }
  
  .game-stats strong, .player-stats strong {
    font-size: 18px;
    display: block;
    margin-top: 5px;
  }
  
  .button-group {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
  }

  h3 {
    margin-top: 3%;
  }

  h4 {
    color: #333;
    text-align: center;
    margin-top: -2%;
  }
  
  button {
    padding: 10px 20px;
    margin: 0 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .underline{
    text-decoration: underline;
  }
