.home-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;  /* Align items to the top */
  padding-top: 20vh;  /* Adjust this value to move the card up */
  background-color: #f5f5f5;
}


.card {
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 400px;
  height: 280px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.loading-card {
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 400px;
  height: 280px;
  display: flex;
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  flex-direction: column;
  overflow: auto; /* Allows scrolling within the card if needed */
}


.title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
}

.uploadContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-grow: 1;
}

.fileInput {
  display: none;
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.plusButton {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 18px;
  font-weight: bold;
}

.uploadButton {
  padding: 10px 20px;
  background-color: #3F9B0A;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.playerResults {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two buttons per row */
  grid-gap: 5px; /* Space between buttons */
  overflow-y: auto; /* Ensures scrollability within the playerResults */
}

.playerButton {
  background-color: #dadada; /* Light gray */
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  height: 35px;
  overflow: hidden;
  white-space: nowrap; /* Prevents text wrapping */
  color: black; /* Ensures text is readable */
}

.playerButton.active {
  background-color: #a9a9a9; /* Dark gray */
}

.netPositive {
  color: green; /* Green for positive values */
}

.netNegative {
  color: red; /* Red for negative values */
}

.toggleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggleLabel {
  display: flex;
  align-items: center;
  font-size: 16px; /* Adjust label font size */
  color: #666; /* Set the text color */
  margin-right: 10px; /* Space between label and switch */
}

.toggleCheckbox {
  position: relative;
  width: 40px; /* Smaller width */
  height: 24px; /* Smaller height */
  -webkit-appearance: none;
  background-color: #ccc;
  outline: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggleCheckbox:checked {
  background-color: #3F9B0A;
}

.toggleCheckbox:before {
  position: absolute;
  content: "";
  width: 20px; /* Smaller knob */
  height: 20px; /* Smaller knob */
  top: 2px; /* Adjust to vertically center */
  left: 2px; /* Adjust to fit within the switch */
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggleCheckbox:checked:before {
  transform: translateX(16px); /* Adjust to the new width */
}

