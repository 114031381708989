body, html {
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  height: 100%;
}

.bankroll-container {
  max-width: 800px;
  margin: 10vh auto; /* Adjusted top margin to push the container down */
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
}

h2 {
  color: #333;
}

.table-wrapper {
  position: relative;
  max-height: 200px;
  overflow-y: auto;
  margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

th, td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

th {
  background-color: #f5f5f5;
  cursor: pointer;
}

th:hover {
  background-color: #e0e0e0;
}

td {
  color: #333;
}

td span {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
}

.button-group {
  margin: 20px 0;
}

button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  background-color: #3F9B0A;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  max-width: 400px;
  width: 100%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 7px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: gray;
}

label {
  display: block;
  margin-bottom: 10px;
  text-align: center;
}

input, select {
  width: 80%;
  padding: 8px;
  margin: 5px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  display: block;
}

input[type="date"] {
  padding: 6px;
}

h3 {
  color: #333;
  text-align: center;
}

button[type="submit"] {
  background-color: #3F9B0A;
  display: block;
  margin: 20px auto 0;
}

@media only screen and (max-width: 768px) {
  .bankroll-container {
    padding: 10px;
  }
  .modal-content {
    width: 90%;
  }
}

.table-wrapper tr {
  position: relative;
}

.delete-button-cell {
  position: absolute;
  right: -17px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.table-wrapper tr:hover .delete-button-cell {
  display: block;
}

.delete-button {
  background: transparent;
  border: none;
  color: #D3D3D3;  /* Light gray color */
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px;
}

.delete-button:hover {
  color: #A9A9A9;  /* Darker gray on hover */
}