.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  .popup-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    text-align: center;
  }
  
  h2 {
    color: #333;
    margin-bottom: 1rem;
  }
  
  p, ul {
    color: #666;
    margin-bottom: 1rem;
  }
  
  ul {
    text-align: left;
    padding-left: 1.5rem;
  }
  
  .subscribe-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .later-button {
    background: none;
    border: none;
    color: #999;
    cursor: pointer;
    font-size: 0.9rem;
  }