    html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}
    
    .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    background-color: #ffffff;
  }
  
  .logo {
    flex-grow: 1;
  }
  
  .logo a {
    color: #28c142;
    text-decoration: none;
    font-size: 27px;
    font-weight: bold;
  }
  
  .nav-links {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  
  .nav-item {
    margin: 0 15px;
    text-decoration: none;
    color: black;
    font-size: 16px;
    font-weight: 500;
  }
  
  .nav-item:hover {
    text-decoration: underline;
  }
  
  .user {
    margin-left: 30px;
    display: flex;
    align-items: center;
  }
  
  .user-icon {
    font-size: 18px;
    color: rgb(83, 83, 83);
  }
  