footer {
    background-color: #fff;
    color: #fff;
    text-align: center;
    padding: 15px 10px;
    margin-top: 30px;
    font-size: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  
  footer a {
    color: #3F9B0A;
    text-decoration: none;
  }
  
  footer a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    footer {
      font-size: 0.9rem;
    }
  }
  