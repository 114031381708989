.user-container {
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5; /* Matches the background color from Home.css */
    top: 0;
  }
  
  .user-icon2 {
    font-size: 100px;  /* Adjust the size of the icon */
    color: #333;  /* You can customize the color */
    margin-top: 60px;
    margin-bottom: 30px;
  }
  
  .user-email {
    font-size: 18px;
    color: #666;
    margin-bottom: 30px;
  }

  .container2{
    padding: 10px 20px;
    height: 30px;
    width: 500px;
    margin-top: 15px;
    font-size: 20px;
    color: #666;
    background-color: white;
    border-radius: 5px;
    text-align: center;
  }
  
  .logout-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: red;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); /* Matches the box-shadow style */
  }
  
  .cancel-subscription-button {
    margin-top: 20px;
    margin-right: 10px; /* Adds spacing to the right of the button */
    padding: 10px 20px;
    background-color: #007bff; /* Choose a different color to distinguish from logout */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); /* Matches the box-shadow style */
  }