.about-container {
    padding: 20px;
    margin: 5vh auto;
    width: 800px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 550px;
    overflow: scroll;
}

.about-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.about-container h2 {
    font-size: 2rem;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #444;
}

.about-container p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
}

.about-container ul {
    list-style-type: disc;
    margin-left: 20px;
}

.about-container li {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #555;
}

.about-container strong {
    color: #222;
}
